.page-header {
    position: relative;
    z-index: 1;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

.page-header-line {
    width: 50px;
    height: 2px;
    background-color: #000;
    margin: 0 auto;
}

.page-header h4 {
    text-transform: uppercase;
    letter-spacing: 4px;
    margin-bottom: 25px;
    line-height: 1;
}

.section-header {
    padding-bottom: 25px;
    padding-top: 25px;
}