footer {
    text-align: center;
    line-height: 1.5em;
    margin-top: 1em !important;
}

ul.social-buttons {
    margin-bottom: 0;
}

.list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
    text-align: center;
}

.list-inline>li {
    display: inline-block;
}

footer span.created-by-label a {
    border-bottom: 0;
    font-style: italic;
}

footer span.copyright {
    font-size: 1.2em;
}

ul.social-buttons li a {
    display: block;
    text-decoration: none;
    outline: 0;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    border-bottom: 0;
    font-size: 1.2em;
    padding: 7px;
    color: rgba(0, 0, 0, 0.55);
}

ul.social-buttons li a:hover {
    color: var(--main-color);
}

ul.social-buttons li a i:hover {
    cursor: var(--cursor-pointer);
}

.legal-links > .link {
    font-size: 0.8em;
    text-decoration: none;
    border-bottom: none;
    margin: 0;
    padding: 0;
    padding-bottom: 5px;
}

.legal-links > .link:hover {
    color: var(--main-color);
}

.link.active {
    color: var(--main-color);
    pointer-events: none;
}