@font-face {
  font-family: "CaviarDreams";
  src: url("./fonts/CaviarDreams.ttf") format("truetype");
 }

 @font-face {
  font-family: "CaviarDreamsBold";
  src: url("./fonts/CaviarDreams_Bold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "CaviarDreamsBoldIt";
  src: url("./fonts/CaviarDreams_BoldItalic.ttf") format("truetype");
  font-style: italic, oblique;
  font-weight: bold;
 }

 @font-face {
  font-family: "CaviarDreamsIt";
  src: url("./fonts/CaviarDreams_Italic.ttf") format("truetype");
  font-style: italic, oblique;
 }

:root {
  --cursor: url(./components/cursor.svg) 16 16, auto;
  --cursor-pointer: url(./components/cursor-pointer.svg) 16 16, pointer;
  --cursor-light: url(./components/cursor-white.svg) 16 16, pointer;
  --main-color: #c9b88a;
  --main-color-bis: #c9b88ab5;
  --main-color-transparent: #c9b88a82;
}

html, body {
  height: 100%;
  font-family: "CaviarDreams", 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

body > #root > div {
  height: calc(100vh - 91px);
}


body {
  /* Needed to manage Navbar fixed top */
  padding-top: 91px; 

  /* Needed for pagination animation without horizontal scrollbar (during animation) */
  max-width: 100 vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

* {
  cursor: var(--cursor);
}


/* Buttons */
.btn-primary:hover {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);

  cursor: var(--cursor);
}

.btn-primary {
  color: black;
  background-color: #fff;
  border-color:  var(--main-color);
  border-radius: 2px;
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #c2c2c2;
  border-color: #c2c2c2;
  background-color: #fff;
}

.btn-check:checked + .btn-primary, 
.btn-check:active + .btn-primary, 
.btn-primary:active, 
.btn-primary.active, 
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: var(--main-color);
  border-color: var(--main-color);
  box-shadow: 0 0 0 0.25rem var(--main-color-transparent);
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--main-color-transparent);
}

.btn-check:checked + .btn-primary:focus, 
.btn-check:active + .btn-primary:focus, 
.btn-primary:active:focus, 
.btn-primary.active:focus, 
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--main-color-transparent);
}


/* Links */
button.link {
  border: none;
  background-color: transparent;
}

a,
button.link {
  color: black;
  text-decoration-line: none;
  border-bottom: 1px solid;
  padding-bottom: 5px;
  cursor: var(--cursor-pointer);
}

a svg,
a svg g,
a svg g ellipse,
a svg g path,
a svg g text {
  cursor: var(--cursor-pointer);
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: var(--cursor-pointer);
}

a:hover {
  color: var(--main-color);
}

.sticky-offset {
  top: 110px;
}