.absolute-wrapper{
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 91px);
}

.content {
    flex: 1 0 auto;
}

.footer {
    flex-shrink: 0;
}