
.about-me-section {
    background: #f8f9fa!important;
}

.services-section {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    /* background-color: grey; */
}

.presentation-title {
    text-align: center;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
}

.services {
    margin-top: 1.5rem;
}

.service .icon {
    line-height: 1.3;
    position: relative;
    margin-top: -60px;
    width: 80px;
    height: 80px;
    background: var(--main-color);
    border-radius: 50%;
    margin-bottom: 15px;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
}

.service:hover .icon {
    background: #fff;
    margin-left: 20px;
}

.service .icon i {
    font-size: 40px;
    color: #fff;
}

.service:hover .icon i {
    color: var(--main-color);
}

.service {
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -ms-transition: all .3s ease;
    transition: all .3s ease;
    width: 100%;
    padding: 30px;
    margin-bottom: 60px;
    min-height: 155px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.service:hover {
    background-color: var(--main-color) !important;
}

.service-item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.service:hover .service-item {
    color: rgba(255,255,255,.8);
}

.service .service-item h3 {
    font-size: 18px;
    font-weight: 600;
}

.service:hover h3 {
    color: #fff;
}

.mb-3 {
    margin-bottom: 1rem!important;
}

.shadow {
    -webkit-box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.rounded-lg {
    border-radius: 0.3rem!important;
}