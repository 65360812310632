.contact-map-container {
    height: 60vh;
    width: 100%;
    margin-bottom: 1em;
}

.contact-map-pointer {
    width: 65px;
    height: 65px;
    position: absolute;
    transform: translate(-50%, -100%);
}

.contact-map-pointer-icon {
    width: 65px;
    height: 65px;
}

.contact-info-container {
    margin-bottom: 1em;
}

.contact-info {
    display: flex;
    flex-direction: row;
}

.contact-info > i {
    font-size: 2.5em;
}

.contact-info-details {
    display: block;
    display: inline-block;
    margin-left: 1em;
}

.contact-info-title {
    font-size: 1.4em;
}

.contact-info-info {
    display: block;
    max-width: 220px;
    margin-bottom: 1em;
}


.contact-form-container {
    margin-top: 0em;
    margin-bottom: 1em;
}

.contact-form-title {
    display: block;
    text-align: center;
    margin-bottom: 1sem;
}

.contact-form-info {
    margin-bottom: 1em;
    display: none;
}

.contact-form-info p {
    margin-bottom: 0em;
}

.contact-form-title .section-heading-line {
    height: 1px;
}

@media (max-width: 992px) {
    .contact-map-container {
        height: 50vh;
        width: 100%;
    }

    .contact-form-container {
        margin-top: 2em;
    }

    .contact-form-title {
        display: block;
        margin-bottom: 1em;
    }
} 

.btn.btn-map {
    position: absolute;
    margin-top: 0.65em;
    margin-left: 0.65em;
    z-index:1000;
}

.field-error {
    color: #721c24;
}



.form-info {
    font-size: 0.8em;
    margin-left: 1em;
    font-style: italic;
}

.form-group {
    margin-bottom: 1em;
}

.form-control {
    border: 1px solid  var(--main-color-bis);
}

.form-control.field-error {
    border: 1px solid #721c24;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--main-color);
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(201 184 138 / 50%);
}

.form-control.field-error:focus {
    border-color: #721c24;
    box-shadow: 0 0 0 0.25rem rgb(114 28 47 / 50%);
}

.notification__item--success {
    background-color: #d4edda;
    border-left: 8px solid #155724;
}

.notification__item--success .notification__title,
.notification__item--success .notification__message {
    color: #155724;
}

.notification__item--success .notification__timer {
    background-color: #d4edda;
}

.notification__item--success .notification__timer-filler {
    background-color: #155724;
}




.notification__item--danger {
    background-color: #f8d7da;
    border-left: 8px solid #721c24;
}

.notification__item--danger .notification__title,
.notification__item--danger .notification__message {
    color: #721c24;
}

.notification__item--danger .notification__timer {
    background-color: #f8d7da;
}

.notification__item--danger .notification__timer-filler {
    background-color: #721c24;
}

.grecaptcha-badge {
    position: absolute !important;
    bottom: 0px !important;
    right: 0px !important;
}

.captcha-container {
    width: 256px;
    height: 60px;
    display: block;
    transition: right 0.3s ease 0s;
    position: absolute;
    right: -186px;
    box-shadow: grey 0px 0px 5px;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1000;
}

.captcha-container:hover {
    right: 0px;
}