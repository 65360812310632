.gallery-image-container {
    overflow: hidden;
    cursor: var(--cursor-pointer);
}

.gallery-image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #fff;
    transition: .3s all ease;
    z-index: 1;
    cursor: var(--cursor-pointer);
}

.gallery-image-overlay h3 {
    margin-bottom: 10px;
    font-size: 1.3em;
    cursor: var(--cursor-pointer);
}

.gallery-image-overlay p {
    font-size: 1.1em;
    color: rgba(255,255,255,.7);
    cursor: var(--cursor-pointer);
}

.gallery-image-overlay .gallery-image-caption {
    position: absolute;
    bottom: 20px;
    left: 20px;
    right: 20px;
    cursor: var(--cursor-pointer);
}

.gallery-image-container img {
    transition: opacity 700ms, transform 700ms;
  }

.gallery-image-container:hover img {
    -webkit-transform: scale3d(1.1,1.1,1);
    transform: scale3d(1.1,1.1,1);
}

.gallery-image-container:hover .gallery-image-overlay {
    background: rgba(0,0,0,.5);
}