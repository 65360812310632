*, *:before, *:after { box-sizing: border-box; }

.nfpMain {
	display: flex;
	align-items: center;
	align-content: center;
    justify-content: center;
}

.nfpMain > div {
    display: flex;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;
}

.nfpMain > div  svg {
    position: relative;
    z-index: 1;		
}

.nfpLogoContainer {
    font-size: 10em;
}

.nfpLogo {
    width: 1em;
    height: 1em;
    vertical-align: -.1em;
    z-index: 10;
    background-color: #fff;
}

.nfpLogoContainer p {
    font-size: 1em;
}

.nfpAfter {
    text-align: left;
    z-index: 1;
    flex-direction: column;
    align-items: start !important;
    align-content: start !important;
}

.nfpAfter p {
    display: block;
    position: relative;
    z-index: 0;
    padding: 0 12;
    line-height: 1.4;
}

.nfpLink {
    text-align: center;
    margin-top: 35px;
}

.nfpLink a {
    text-decoration: none;
    height: 55px;
    line-height: 55px;
    border: 1px solid black;
    border-radius: 20px;
    display: inline-block;
    padding: 0 20px;
    color: black;
}

.nfpLink a:hover {
    font-weight: bold;
}