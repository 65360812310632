.main-container {
    height: 100vh;
    margin-top: -91px;
}

.main-row {
    height: 100vh;
}

.text-align-center {
    text-align: center;
}

.center {
    text-align: center;
}

.animatedLogo {
    max-height: 245px;
    display: block;
    margin: auto;
}

.animatedButton {
    display: inline-block;
    height: 129.35px;
    width: 175px;
}

.animatedButton-little {
    height: 75px;
    width: 100%;
    max-width: 350px;
    line-height: 75px;
    border: 1px solid black;
    border-radius: 20px;
    margin-bottom: 15px;
    display: inline-block;
}

.animatedButton-little svg {
    float: left;
    width: 8.5em;
    margin-left: 25px;
    margin-top: -12.75px;
}

.animatedButton-little .button-label {
    display: inline;
    font-size: 1.3em;
}

.animatedButtonLink {
    text-decoration: none !important;
    color: black !important;
    border-bottom: 0;
}

.animatedButton.top {
    margin-bottom:50px;
}

.animatedButton.bottom-right {
    margin-right:85px;
}

.animatedButton.bottom-left {
    margin-left:85px;
}

/* Primary input mechanism cannot hover 
at all or cannot conveniently hover 
(e.g., many mobile devices emulate hovering
when the user performs an inconvenient long tap), 
or there is no primary pointing input mechanism */
@media (hover: none) { 

    @media (max-width: 767.5px) {
        .animatedLogo {
            margin-top: 100px;
            margin-bottom: 100px;
        }
    } 

    .animatedButton .buttonLabel {
        transform: translate(0px, -20px);
    }
}

a[disabled] {
    pointer-events: none;
}

a.disabled {
    pointer-events: none;
}

body > #root > .adiv {
    height: 100%;
}