.breadcrumb {
    margin-top: 1.1em;
    margin-left: 1.1em;
}

.breadcrumb-item.active {
    font-weight: 600;
    color: var(--main-color) !important;
}

.projects-container {
    position: relative;
    -webkit-box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    padding: 1em;
    /* background-color: #f8f8f8; */
    border: 0.1px solid rgb(0 0 0 / 10%);
}

.project-details-description {
    -webkit-box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    padding: 1em;
    /* border: 0.1px solid rgb(0 0 0 / 10%); */
    margin-top: 5px;
    /* margin-bottom: 10px; */
    /* height: 100%; */
    /* min-height: 80vh; */
    background-color: var(--main-color-transparent);
}

.project-details-description-v1 {
    -webkit-box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    box-shadow: 0 3px 5px 0 rgb(0 0 0 / 10%);
    padding: 1em;
    border: 0.1px solid rgb(0 0 0 / 10%);
    margin-top: 5px;
    margin-bottom: 10px;
}

@media (min-width: 992px) {
    .project-details-description {
        min-height: 85vh;
    }
    .project-details-images {
        margin-top: 0px;
    }
} 

@media (max-width: 992px) {
    .project-details-images {
        margin-top: 10px;
    }
} 

.projects-control {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5%;
    text-align: center;
    height: 100vh;
    margin-top: -20px;
}

.projects-control.left {
    left: 0;
    margin-left: 1em;
}

.projects-control.right {
    right: 0;
    margin-right: 1em;
}

.projects-control-next, 
.projects-control-prev {
    opacity: .5;
    transition: opacity .15s ease;
    cursor: var(--cursor-pointer);
    width: 50px;
    height: 50px;
    border: 1px solid black;
    border-radius: 25px;
}

.projects-control-next.square,
.projects-control-prev.square {
    width: 100%;
    height: auto;
    border-radius: 0;
}

.projects-control-next i {
    margin-left: 4px;
}

.projects-control-prev i {
    margin-right: 4px;
}

.btn.projects-control-next i, 
.btn.projects-control-prev i {
    cursor: var(--cursor-pointer);
}

.btn.projects-control-next:hover, 
.btn.projects-control-prev:hover,
.btn.projects-control-next:hover i, 
.btn.projects-control-prev:hover i,
.btn.projects-control-next i:hover, 
.btn.projects-control-prev i:hover {
    color: var(--main-color);
}

button.projects-control-next, 
button.projects-control-prev,
.btn.projects-control-next, 
.btn.projects-control-prev   {
    color: #000000;
    background-color: transparent;
    font-size: 2em;
    padding: 0;
    margin: 0;
    box-shadow: none !important;
}

.projects-control-container {
    z-index: 1;
}

.projects-control-container .projects-control-next,
.projects-control-container .projects-control-prev {
    margin-top: 25vh;
}

.fullscreen > .react-images__positioner {
    top: 91px !important;
}

.fullscreen-enabled > .react-images__positioner {
    top: 0 !important;
}

.details.gallery-image-overlay h3 i {
    font-size: 1.5em;
    cursor: var(--cursor-pointer);
}

.details.gallery-image-overlay {
    text-align: center;
}

.details.gallery-image-overlay .gallery-image-caption {
    position: absolute;
    top: calc(50% - 1.5em);
    cursor: var(--cursor-pointer);
}

.images-comparer {
    margin-top: 5px;
    margin-bottom: 5px;
}

.image-compared-container {
    padding-left: 5px;
    padding-right: 5px;
}

.image-compared {
    display: block; 
    width: 100%; 
    height: 100%; 
    max-width: 100%; 
    box-sizing: border-box;
    object-fit: cover; 
    object-position: center;
}

.image-compared-label {
    position: absolute;
    margin-right: 5px;
    margin-left: 5px;
    margin-top: 5px;
    background-color: #ffffff87;
    padding: 0.2em;
}

.image-compared-label.right {
    right:5px;
}

.project-description-line {
    margin-bottom: 0.5em;
}

.project-description-subtitle {
    font-weight: bold;
    color: #515151;
    width: 150px;
}

.project-description-content {
    width: calc(100% - 150px);
}

.title-heading-line {
    width: 50px;
    height: 1px;
    background-color: #000;
    margin: 0 auto;
    margin-top: 0.75em;
    margin-bottom: 1em;
}


/*
* Display light cursor on image fullscreen. Due to drak border on fullscreen, we can not see main cursor.
*/
.fullscreen,
.react-images__blanket,
.react-images__header,
.react-images__header--isFullscreen,
.react-images__header span button svg,
.react-images__header span button svg path,
.react-images__footer,
.react-images__footer span,
.react-images__view,
.react-images__view-image,
.react-images__container,
.react-images__container--isFullscreen,
.react-images__container--isFullscreen > button,
.react-images__track,
.react-images__view--isFullscreen,
.react-images__navigation button svg,
.react-images__navigation button svg path {
    cursor: var(--cursor-light);
}
